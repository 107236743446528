'use client';

import * as React from 'react';
import { useState } from 'react';
import ControlInput from '@/components/ui/ControlInput';
import { SearchCompanyItem } from '@/apiTypes/types';
import useCompanySearch from '@/lib/hooks/useCompanySearch';

interface IProps {
  onSelect: (company: SearchCompanyItem) => void;
  onReset: () => void;
}

const CompanyAutoComplete: React.FC<IProps> = (props) => {
  const [keyword, setKeyword] = useState('');
  const [selected, setSelected] = useState(false);
  const { search, results, loading, clearResults } = useCompanySearch();

  const onSelect = (company: SearchCompanyItem) => {
    setSelected(true);
    setKeyword(company.name);
    clearResults();
    props.onSelect(company);
  };

  const reset = () => {
    setSelected(false);
    props.onReset();
  };

  const onKeyword = (val: string) => {
    if (selected) {
      reset();
    }
    setKeyword(val);
    search(val);
  };

  return (
    <ControlInput
      value={keyword}
      onChange={(e) => onKeyword(e.target.value)}
      menu={results.map((res) => ({
        id: `${res.id}`,
        label: res.name,
        onClick: () => onSelect(res),
      }))}
      icon="search"
      loading={loading}
    />
  );
};

export default CompanyAutoComplete;
