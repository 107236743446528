import { SearchV3Response } from '@/apiTypes/types';
import { loadSearchData } from '@/lib/dataProviders/search/loadSearchData';
import { SearchV3Scope } from '@/lib/enums/searchScope';

type SearchParams = {
  query?: string;
  scope?: SearchV3Scope;
};

type PaginationProps = {
  from: number;
  limit: number;
};

export async function loadSearch(search: SearchParams, pagination: PaginationProps): Promise<SearchV3Response> {
  const result = await loadSearchData<SearchV3Response>({
    uri: '/v3/search',
    params: {
      query: search?.query,
      scope: search?.scope,
      from: pagination.from.toString(),
      limit: pagination.limit.toString(),
    },
    revalidate: 0,
  });
  if (!result) {
    return {
      scope: search.scope as SearchV3Response['scope'],
      items: [],
      counts: {},
      pagination: {
        from: pagination.from,
        limit: pagination.limit,
        total: 0,
        isEnd: true,
      },
    };
  }
  return result;
}
