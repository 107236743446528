'use client';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useOverlay } from '@/components/hooks/useOverlay';
import Overlay from '@/components/ui/Overlay';
import LayoutOverlay from '@/components/ui/LayoutOverlay';
import Block from '@/components/ui/Block';
import OverlayTitle from '@/components/ui/LayoutOverlay/OverlayTitle';
import NonIdealState from '@/components/ui/NonIdealState';
import AddDebtForm from '@/components/content/AddDebtForm';
import { InfopankCompany, InfopankCompanyDebts } from '@/apiTypes/types';
import { fullInfopankAccess } from '@/lib/helpers/access';
import { OverlayKey } from '@/lib/enums/overlayKey.enum';
import { afterOverlayClose } from '@/lib/helpers/overlays';

interface IProps {
  companyDebtInfo?: InfopankCompanyDebts;
  company?: InfopankCompany;
  hasCompanyAutocompleteAccess?: boolean;
}

const OverlayAddDebt: React.FC<IProps> = ({ companyDebtInfo, company, hasCompanyAutocompleteAccess }) => {
  const [submitted, setSubmitted] = React.useState(false);
  const { isOverlayOpen, zIndexOfOverlay, closeOverlay } = useOverlay(OverlayKey.lisa_volg);
  const { t } = useTranslation();

  const onClose = () => {
    closeOverlay();
    afterOverlayClose(() => setSubmitted(false));
  };

  if ((!fullInfopankAccess(companyDebtInfo?.hasAccess) || !companyDebtInfo?.content?.url) && !hasCompanyAutocompleteAccess) {
    return null;
  }

  return (
    <Overlay
      isOpen={isOverlayOpen}
      onClose={onClose}
      layout="default"
      zIndex={zIndexOfOverlay}
      hideClose={true}
    >
      <LayoutOverlay size="default">
        <Block
          width="narrow"
          theme="lighter"
        >
          {submitted ? (
            <React.Fragment>
              <OverlayTitle onClose={onClose} />
              <NonIdealState
                title={t('debtForm.addedDebt')}
                imageUrl="/img/success.png"
                buttons={[
                  {
                    title: t('generic.actions.closeMessage'),
                    theme: 'primary',
                    onClick: onClose,
                  },
                ]}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <OverlayTitle
                title={t('company.addDebt')}
                onClose={closeOverlay}
              />
              <AddDebtForm
                hasCompanyAutocompleteAccess={hasCompanyAutocompleteAccess}
                companyName={company?.name}
                companyRegNr={company?.regNr}
                debtUrl={companyDebtInfo?.content?.url}
                onCancel={closeOverlay}
                setSubmitted={setSubmitted}
              />
            </React.Fragment>
          )}
        </Block>
      </LayoutOverlay>
    </Overlay>
  );
};

OverlayAddDebt.displayName = 'OverlayAddDebt';

export default OverlayAddDebt;
