'use client';

import { useState } from 'react';
import { SearchCompanyItem } from '@/apiTypes/types';
import { SearchV3Scope } from '@/lib/enums/searchScope';
import { loadSearch } from '@/lib/dataProviders/search/loadSearch';

const useCompanySearch = () => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<SearchCompanyItem[]>([]);

  const getCompanies = async (q: string) => {
    setLoading(true);
    const result = await loadSearch(
      {
        query: q,
        scope: SearchV3Scope.companies,
      },
      {
        from: 0,
        limit: 10,
      },
    );
    const companies = result.items as SearchCompanyItem[];
    if (companies.length) {
      setResults(companies);
    }
    setLoading(false);
  };

  const search = (q: string) => {
    if (q) {
      void getCompanies(q);
    } else {
      setResults([]);
    }
  };

  const clearResults = () => {
    setResults([]);
  };

  return {
    loading,
    results,
    search,
    clearResults,
  };
};

export default useCompanySearch;
