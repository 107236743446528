'use client';

import * as React from 'react';
import classNames from 'classnames';
import ControlCheckbox, { IControlCheckboxProps } from '../ControlCheckbox';
import styles from './styles.module.scss';

export interface IControlCheckboxWithLabelProps
  extends Pick<IControlCheckboxProps, 'checked' | 'disabled' | 'onChange' | 'hasError' | 'name' | 'id'> {
  /** Label to display */
  label: string | React.ReactNode;
  /** Error */
  hasError?: boolean;
  /** DOM target element name attribute */
  name?: string;
  /** DOM target element id attribute */
  id?: string;
}

/**
 * ControlCheckbox extension to display label before Checkbox element.
 */
export const ControlCheckboxWithLabel: React.FunctionComponent<IControlCheckboxWithLabelProps> = (props) => {
  const checkboxRef = React.createRef<HTMLInputElement>();

  return (
    <div className={styles['checkbox-with-label']}>
      <ControlCheckbox
        checked={props.checked}
        disabled={props.disabled}
        onChange={props.onChange}
        hasError={props.hasError}
        name={props.name}
        id={props.id}
        inputRef={checkboxRef}
      />
      <div
        className={styles['checkbox-with-label__label']}
        onClick={() => {
          if (checkboxRef.current) {
            checkboxRef.current.focus();
            checkboxRef.current.click();
          }
        }}
      >
        <span
          className={classNames(styles['checkbox-with-label__label__text'], {
            [styles['has-error']]: props.hasError,
          })}
        >
          {props.label}
        </span>
      </div>
    </div>
  );
};

ControlCheckboxWithLabel.displayName = 'ControlCheckboxWithLabel';

export default ControlCheckboxWithLabel;
