'use client';

import { CookieKey } from '@/lib/enums/cookieKey.enum';
import { getCookie } from '@/lib/helpers/cookie';
import { getChannelId } from '@/lib/getChannelId';

type RequestParams = {
  debtInfoUrl: string;
  data: FormData;
};

type RequestStatusCode = 'error' | 'success';

type InfopankDebt = {
  status: RequestStatusCode;
  data?: Record<string, string[]>;
};

export async function postInfopankDebt({ debtInfoUrl, data }: RequestParams): Promise<InfopankDebt> {
  try {
    const response = await fetch(debtInfoUrl, {
      method: 'POST',
      body: data,
      headers: {
        'Authorization': `Bearer ${getCookie(CookieKey.jwt)!}`,
        'x-channel-id': getChannelId(),
      },
    });

    if (response.status >= 400) {
      const body = (await response.json()) as { errors?: Record<string, string[]> } | null;
      return {
        status: 'error',
        data: body?.errors,
      };
    }

    return {
      status: 'success',
    };
  } catch (error) {
    return {
      status: 'error',
    };
  }
}
