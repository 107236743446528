'use client';

import * as React from 'react';
import { useState } from 'react';
import { DateTime } from 'luxon';
import { Trans, useTranslation } from 'react-i18next';
import LayoutForm from '@/components/ui/LayoutForm';
import FormRow from '@/components/ui/FormRow';
import ControlInput from '@/components/ui/ControlInput';
import Buttons from '@/components/ui/Buttons';
import ButtonsPrimary from '@/components/ui/Buttons/Primary';
import Button from '@/components/ui/Button';
import ControlCheckboxWithLabel from '@/components/ui/ControlCheckboxWithLabel';
import ButtonsSecondary from '@/components/ui/Buttons/Secondary';
import { useOverlay } from '@/components/hooks/useOverlay';
import ControlDate from '@/components/ui/ControlDate';
import { formatDate, sanitizeFloatInput } from '@/lib/helpers/format';
import UploadFile from '@/components/forms/UploadFile';
import Callout from '@/components/ui/Callout';
import { IDebtInfo } from '@/components/content/AddDebtForm/interfaces';
import { accept, fileAllowedFormats, formHasError, initialState } from '@/components/content/AddDebtForm/helper';
import { postInfopankDebt } from '@/lib/dataProviders/postInfopankDebt';
import { OverlayKey } from '@/lib/enums/overlayKey.enum';
import useGaEvents from '@/lib/hooks/useGaEvents';
import { AnalyticsId } from '@/lib/enums/analyticsId.enum';
import CompanyAutoComplete from '@/components/overlays/AddDebt/fields/CompanyAutoComplete';

export type IFormErrors = Partial<Record<keyof IDebtInfo, string> & { conditions: string }>;
const errorsInitialState = {};
type formDataType = string | Blob;

interface IProps {
  setSubmitted?: (submitted: boolean) => void;
  onCancel?: () => void;
  companyName?: string;
  companyRegNr?: string;
  debtUrl?: string;
  hasCompanyAutocompleteAccess?: boolean;
}

const getDebtUrl = (regCode: number) => `${process.env.NEXT_PUBLIC_CMS_INFOPANK_API_URL}/v3/company/${regCode.toString()}/debt-info`;

const AddDebtForm: React.FunctionComponent<IProps> = (props) => {
  const { openOverlay } = useOverlay(OverlayKey.lisa_volg_tingimused);
  const [form, setForm] = useState<IDebtInfo>(initialState);
  const [errors, setErrors] = useState<IFormErrors>(errorsInitialState);
  const [conditionsAgreed, setConditionsAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { trackGenerateLead } = useGaEvents();
  const [companyName, setCompanyName] = useState(props.companyName);
  const [companyRegNr, setCompanyRegNr] = useState(props.companyRegNr);
  const [debtUrl, setDebtUrl] = useState(props.debtUrl);

  const setFieldError = (id: string, error?: string) =>
    setErrors((prev) => ({
      ...prev,
      [id]: error,
    }));

  const onChange = (value: string | File[] | null, id: keyof IDebtInfo) => {
    setForm((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const setFiles = (files: File[]) => {
    setForm((prevForm) => ({
      ...prevForm,
      debtFiles: files,
    }));
  };

  const onDebtSumChange = (value: string) => {
    onChange(sanitizeFloatInput(value), 'debtSum');
  };

  const setFormInitialState = () => {
    setForm(initialState);
    setErrors(errorsInitialState);
    setConditionsAgreed(false);
    if (props.setSubmitted) {
      props.setSubmitted(true);
    }
  };

  const onSuccess = () => {
    trackGenerateLead({
      name: AnalyticsId.add_debt,
      section: 'lead',
    });
    setFormInitialState();
  };

  const onSubmit = async () => {
    if (!conditionsAgreed) {
      setFieldError('conditions', t('debtForm.conditions'));
      return;
    }

    if (!debtUrl || !companyName || !companyRegNr) {
      return;
    }

    setLoading(true);
    setErrors(errorsInitialState);
    const asFormData = new FormData();
    Object.entries(form).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          asFormData.append(key, v as formDataType);
        });
      } else {
        asFormData.append(key, value as formDataType);
      }
    });

    try {
      const result = await postInfopankDebt({
        debtInfoUrl: debtUrl,
        data: asFormData,
      });

      if (!result || result.status === 'error') {
        const errors = result?.data || { unknown: ['Request failed'] };
        const formattedErrors = Object.entries(errors).reduce((acc: IFormErrors, [key, errors]) => {
          /** Error keys start with capital letter. */
          const formattedKey = `${key[0].toLowerCase()}${key.substr(1)}` as keyof IDebtInfo;
          const [value] = errors;
          acc[formattedKey] = value;
          return acc;
        }, {});
        setErrors(formattedErrors);
      } else {
        onSuccess();
      }
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {formHasError(errors) && (
        <Callout
          intent="danger"
          text={t('debtForm.error')}
        />
      )}
      <h4>{t('debtForm.details')}</h4>
      <LayoutForm>
        <FormRow
          label={t('debtForm.name')}
          required={t('debtForm.required')}
          for="send-message-creditorName"
          size="narrow"
          hasError={errors.creditorName}
        >
          <ControlInput
            id="send-message-creditorName"
            disabled={loading}
            hasError={!!errors.creditorName}
            onChange={(e) => onChange(e.target.value, 'creditorName')}
          />
        </FormRow>
        <FormRow
          label={t('debtForm.registerCode')}
          required={t('debtForm.required')}
          for="send-message-creditorRegNo"
          size="narrow"
          hasError={errors.creditorRegNo}
        >
          <ControlInput
            id="send-message-creditorRegNo"
            disabled={loading}
            hasError={!!errors.creditorRegNo}
            onChange={(e) => onChange(e.target.value, 'creditorRegNo')}
          />
        </FormRow>
        <FormRow
          label={t('debtForm.contact')}
          required={t('debtForm.required')}
          for="send-message-creditorContactPerson"
          size="narrow"
          hasError={errors.creditorContactPerson}
        >
          <ControlInput
            id="send-message-creditorContactPerson"
            disabled={loading}
            hasError={!!errors.creditorContactPerson}
            onChange={(e) => onChange(e.target.value, 'creditorContactPerson')}
          />
        </FormRow>
        <FormRow
          label={t('debtForm.email')}
          required={t('debtForm.required')}
          for="send-message-creditorEmail"
          size="narrow"
          hasError={errors.creditorEmail}
        >
          <ControlInput
            id="send-message-creditorEmail"
            disabled={loading}
            hasError={!!errors.creditorEmail}
            onChange={(e) => onChange(e.target.value, 'creditorEmail')}
          />
        </FormRow>
        <FormRow
          label={t('debtForm.phoneNumber')}
          required={t('debtForm.required')}
          for="send-message-creditorPhone"
          size="narrow"
          hasError={errors.creditorPhone}
        >
          <ControlInput
            id="send-message-creditorPhone"
            disabled={loading}
            hasError={!!errors.creditorPhone}
            onChange={(e) => onChange(e.target.value, 'creditorPhone')}
          />
        </FormRow>
      </LayoutForm>
      <h4>{t('debtForm.debtDetails')}</h4>
      <LayoutForm>
        <FormRow
          label={t('debtForm.debtName')}
          required={t('debtForm.required')}
          size="narrow"
        >
          {props.hasCompanyAutocompleteAccess ? (
            <CompanyAutoComplete
              onSelect={(company) => {
                setCompanyName(company.name);
                setCompanyRegNr(company.registrationCode);
                setDebtUrl(getDebtUrl(company.id));
              }}
              onReset={() => {
                setCompanyRegNr('');
                setCompanyName('');
                setDebtUrl('');
              }}
            />
          ) : (
            <ControlInput
              value={companyName}
              disabled={true}
              readonly={true}
            />
          )}
        </FormRow>
        <FormRow
          label={t('debtForm.debtRegisterCode')}
          required={t('debtForm.required')}
          size="narrow"
        >
          <ControlInput
            value={companyRegNr}
            disabled={true}
            readonly={true}
          />
        </FormRow>
        <FormRow
          label={t('debtForm.debtTime')}
          required={t('debtForm.required')}
          for="send-message-debtTime"
          size="narrow"
          description={t('debtForm.debtTimeDescription')}
          hasError={errors.debtTime}
        >
          <ControlDate
            id="send-message-debtTime"
            disabled={loading}
            hasError={!!errors.debtTime}
            onChange={(e) =>
              onChange(formatDate((e.target as HTMLInputElement).value, 'yyyy-LL-dd') || DateTime.now().toFormat('yyyy-LL-dd'), 'debtTime')
            }
          />
        </FormRow>
        <FormRow
          label={t('debtForm.debtSum')}
          required={t('debtForm.required')}
          for="send-message-debtSum"
          size="narrow"
          hasError={errors.debtSum}
        >
          <ControlInput
            id="send-message-debtSum"
            disabled={loading}
            value={form.debtSum}
            hasError={!!errors.debtSum}
            onChange={(e) => onDebtSumChange(e.target.value)}
          />
        </FormRow>
        <FormRow
          label={t('debtForm.debtDocuments')}
          required={t('debtForm.required')}
          for="send-message-files"
          description={t('debtForm.debtDocumentFormat', { formats: fileAllowedFormats })}
          hasError={errors.debtFiles}
        >
          <div>
            <UploadFile
              accept={accept}
              loading={loading}
              files={form.debtFiles}
              setFiles={setFiles}
            />
          </div>
        </FormRow>
        <FormRow>
          <p>
            {t('debtForm.paymentInfo')} <a href="mailto:info@infopank.ee">info@infopank.ee</a>. {t('debtForm.paymentInfoSecond')}
          </p>
        </FormRow>
        <FormRow>
          <ControlCheckboxWithLabel
            label={
              <Trans
                i18nKey="debtForm.agreement.text"
                components={{
                  1: (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        openOverlay();
                      }}
                    />
                  ),
                }}
              />
            }
            checked={conditionsAgreed}
            hasError={!!errors.conditions}
            onChange={() => {
              setFieldError('conditions', undefined);
              setConditionsAgreed((prev) => !prev);
            }}
          />
        </FormRow>
      </LayoutForm>
      <Buttons>
        <ButtonsSecondary>
          <Button
            title={t('debtForm.cancel')}
            theme="link"
            iconLeft="close"
            onClick={props.onCancel}
            size="large"
          />
        </ButtonsSecondary>
        <ButtonsPrimary>
          <Button
            title={t('debtForm.send')}
            theme="primary"
            size="large"
            disabled={!companyName || !companyRegNr || !debtUrl}
            onClick={() => {
              void onSubmit();
            }}
          />
        </ButtonsPrimary>
      </Buttons>
    </React.Fragment>
  );
};

AddDebtForm.displayName = 'AddDebtForm';

export default AddDebtForm;
